.tree {
  margin-right: 1.25rem;
  width: 100%;
  background: #020f1d;
  border-radius: 0.625rem;
  height: 100%;
  padding: 0.9375rem;

  &_isMobile {
    margin-left: 0;
    border-radius: 0;
    overflow-y: auto;
  }

  &__title {
    padding: 0.3125rem 0 0.625rem 0.625rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
  }

  &__back-arrow {
    width: 1.875rem;
    min-width: 1.875rem;
    display: flex;
    transform: rotate(180deg);
  }

  &_active &__current-agent {
    background: #da4167;
  }

  &__current-agent {
    display: flex;
    border-radius: 0.625rem;
    background: #292c36;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.125rem;
    padding: 0.3125rem;
    height: 2.5rem;

    &__wrapper {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
    }

    &:hover {
      background: #da4167;
    }

    &:hover &__img-user {
      * {
        fill: #fff;
      }
    }

    &__img-user {
      width: 1.25rem;
      min-width: 1.25rem;
      &_active {
        * {
          fill: #fff;
        }
      }
    }

    &__title {
      font-weight: 700;
      font-size: 0.875rem;
      color: #fff;
      margin-left: 0.625rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__img-arrow {
      width: 1.875rem;
      min-width: 1.875rem;
      transform: rotate(180deg);

      &_opened {
        transform: rotate(0);
      }
    }
  }

  &__wrapper {
    overflow-x: auto;
    padding: 0 0 0.625rem;
  }
}
