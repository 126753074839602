.input-date-desktop {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;

  & .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__triangle {
    display: none;
  }

  &_withManualTyping {
    & .react-datepicker-wrapper {
      width: 100%;
      height: fit-content;
    }
    & .react-datepicker__input-container {
      & input {
        background-color: #020f1d;
        border: none;
        color: #fff;
      }
    }
  }

  &_disabled {
    cursor: default;
  }

  &__custom-date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    background: #020f1d;
    border: 0.0625rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.625rem;

    &-calendar {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0.3125rem 0 0;
    }
    &-value {
      margin-right: auto;
      font-size: 0.875rem;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #ffffff;

      &_fontSize {
        &_12 {
          font-size: 0.75rem;
          font-weight: 500;
        }
      }
    }
    &-arrow {
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &_disabled &__custom-date-input {
    background-color: transparent;

    &_color {
      &_grey {
        background: #292C36;
      }
    }
  }

  &_validState_1 &__custom-date-input {
    // border: 0.0625rem solid #ffffff !important;
  }
  &_validState_2 &__custom-date-input {
    border: 0.0625rem solid #0d9252 !important;
  }
  &_validState_3 &__custom-date-input {
    border: 0.0625rem solid #d31c32 !important;
  }
}
