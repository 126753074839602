.app {
  width: 100%;
  height: 100%;
  background: #030713;
  color: #fff;
  display: flex;

  &__test {
    width: 5rem;
    height: 5rem;
  }

  &__header {
    width: 100%;
    position: relative;
    margin-top: 4rem;
  }

  &__wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &__main {
      position: relative;
      height: 100%;
      width: 100%;
    }

    &__content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 1.25rem;
    }
  }

  &__user-tree {
    width: 20%;
  }
}

@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,700,900");

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #d9d9d9;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}

html {
  font-size: 16px;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}
