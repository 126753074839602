.information-tree-row {
  position: relative;

  &__line {
    height: 2.5rem;
    width: 1.25rem;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;

    &_type_3 {
      left: 0.0313rem;
      width: 1.1875rem;
      top: -0.0313rem;
    }
    &_type_2 {
      left: -1.5px;
    }
  }

  &__parent {
    display: flex;
    margin-left: 1.25rem;
    border-radius: 0.625rem;
    background: #292c36;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.3125rem;
    margin-bottom: 0.125rem;
    height: 2.5rem;
    box-sizing: border-box;
    border: 1px solid #292c36;
    &:hover,
    &_active {
      border: 1px solid #da4167;
    }
    &__wrapper {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
    }
    &__img-user {
      width: 1.125rem;
      min-width: 1.125rem;
    }
    &__title {
      font-weight: 700;
      font-size: 0.875rem;
      color: #fff;
      margin-left: 0.625rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__img-arrow {
      width: 1.875rem;
      min-width: 1.875rem;
      transform: rotate(180deg);
      &_opened {
        transform: rotate(0);
      }
    }
  }

  &__childrens {
    position: relative;
    padding-left: 1.25rem;
  }
}
