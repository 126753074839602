.disable-user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__wrapper {
    background: #020f1d;
    border-radius: 1.25rem;
    padding: 3.125rem 150px 4.6875rem 9.375rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(13vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__header {
    font-weight: 500;
    margin-bottom: 3.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &-title {
      font-size: 1.875rem;
      text-transform: capitalize;
      margin: 0.625rem 0;
    }
    &-description {
      font-size: 1rem;
      & span {
        color: #da4167;
      }
    }
    &-icon {
      display: flex;
      min-width: 5rem;
      width: 5rem;
    }
  }
  &__user-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 1.375rem 0.625rem 0.625rem;
    background: #292c36;
    border-radius: 0.625rem;
    &:first-child {
      margin-right: 1.875rem;
    }
    &-right {
      & span {
        display: block;
        text-align: right;
        &:first-child {
          font-weight: 500;
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.4);
          text-align: left;
        }
        &:last-child {
          font-weight: 700;
          font-size: 0.875rem;
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      display: flex;
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 1.25rem;
    }
    &-info {
      font-weight: 700;
      font-size: 0.875rem;
      &-type {
        font-size: 0.75rem;
        text-transform: uppercase;
        padding: 0.1875rem 0.375rem;
        border-radius: 0.3125rem;
        background: #0f78f2;
        width: fit-content;
        margin-top: 0.3125rem;
        &_role_6 {
          background: #0d9252;
        }
      }
    }
  }

  &__top {
    width: 100%;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    margin-top: 3.125rem;
    width: 100%;
    & button {
      width: fit-content;
      padding: 0.7188rem 1.25rem;
      text-transform: uppercase;
      margin-left: 1.25rem;
      &:last-child {
        color: #d31c32;
      }
    }
  }
}
