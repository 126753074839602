.sidebar {
  background: #020f1d;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 17.5rem;
  padding: 1.25rem 0.5rem 1.25rem 1.25rem;
  z-index: 9999;
  overflow: hidden;
  width: 20%;

  &__menu {
    overflow: auto;
    padding-right: 0.5rem;

    &::-webkit-scrollbar {
      width: 4px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(41, 44, 54, 1);
      border-radius: 0.625rem;
    }
  }

  &__logo {
    max-width: 10.5rem;
    width: 100%;
    margin-bottom: 0.625rem;
    &-wrapper {
      display: flex;
      justify-content: center;
    }
    &_isMobile {
      max-width: 10.5rem;
      margin: 3.1875rem 0;
    }
  }
}
