@import "shared/style";

.paginator {
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  align-items: center;
  margin-left: 0.3125rem;

  &__pages {
    display: flex;
    height: 100%;
  }
  &__main {
    display: flex;
  }
  &__page {
    display: flex;
    align-items: center;
    justify-content: center;

    &__first-img,
    &__previous-img,
    &__next-img,
    &__last-img {
      width: 0.8125rem;
      height: 1.125rem;
      &_active {
        * {
          fill: #fff;
        }
      }
    }

    &__previous-img {
      margin-left: 1.25rem;
    }

    &__next-img {
      margin-right: 1.25rem;
    }

    &_active {
      background: #292c36;
      border-radius: 0.3125rem;
      cursor: pointer;
      padding: 0.4375rem 1.875rem;
      color: #fff;
      width: 1.875rem;
      height: 1.875rem;
      margin: 0 0.625rem;
    }
  }

  &__select {
    width: 5rem;
  }

  @media (max-width: $paginationWidth) {
    justify-content: center;
    flex-direction: column;
    &__select {
      margin-top: 0.8rem;
    }
  }
}
