.input-money {
  box-sizing: border-box;
  display: block;
  border: none;
  width: 100%;
  min-height: 2.5rem;
  outline: none;
  padding-left: 1rem;
  color: #fff;
  font-weight: 400;
  font-size: 0.875rem;
  background: #020f1d;
  border: 0.0625rem solid rgba(255, 255, 255, 0.3);
  border-radius: 0.625rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
    font-weight: 400;
    font-size: 0.875rem;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &_sizable {
    &_default {
      height: 100%;
    }
    &_low {
      height: 2.5rem;
    }
    &_high {
      height: 3.125rem;
    }
    &_rounded {
      border-radius: 1.17rem;
    }
  }

  &_type {
    &_date {
      font-size: 0.75rem;
      font-family: "Segoe UI", sans-serif;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &_number {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &_modal {
      background: #f4f4f4;
      border-width: 0.0625rem 0.0625rem 0.0625rem 0px;
      border-style: solid;
      border-color: #e2e5ec;
      border-radius: 0 0.3125rem 0.3125rem 0px;

      &::placeholder {
        font-weight: 400;
        font-size: 0.875rem;
        color: #9899a2;
      }
    }

    &_modalSecond {
      background: #ffffff;
      border: 0.0625rem solid #e2e5ec;
      border-radius: 0.3125rem;

      &::placeholder {
        font-weight: 400;
        font-size: 1rem;
        color: #9899a2;
      }
    }

    &_lowWhite {
      background: #ffffff;
      border-radius: 0.3125rem;

      &::placeholder {
        font-weight: 400;
        font-size: 0.875rem;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__password {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    cursor: pointer;

    &-svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &_color {
    &_white {
      background: #fff;
      padding-left: 2.125rem;
      color: #7e89a4;
      &::placeholder {
        color: #7e89a4;
      }
    }
  }

  &_validState_1 {
    // border: 0.0625rem solid #ffffff !important;
  }
  &_validState_2 {
    border: 0.0625rem solid #0d9252 !important;
  }
  &_validState_3 {
    border: 0.0625rem solid #d31c32 !important;
  }
}
