.header-dropdown {
  position: absolute;
  top: 5rem;
  width: 18.75rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0.3);
  border-radius: 0.625rem;
  overflow: hidden;

  &__upper {
    height: 40%;
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #020f1d;
  }

  &__balance {
    text-align: right;
    & span {
      display: block;
      &:first-child {
        font-weight: 500;
        font-size: 0.75rem;
        color: rgba(255, 255, 255, 0.4);
      }
      &:last-child {
        color: #fff;
        font-weight: 700;
        font-size: 0.875rem;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    max-width: 50%;
    &-img {
      width: 1.875rem;
      height: 1.875rem;
      min-width: 1.875rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-name {
      color: #fff;
      text-transform: uppercase;
      margin-left: 0.625rem;
      width: 100%;
      & span {
        display: block;
        &:first-child {
          font-weight: 700;
          text-overflow: ellipsis;
          font-size: 0.875rem;
          overflow-x: hidden;
        }
        &:last-child {
          font-weight: 500;
          font-size: 0.75rem;
        }
      }
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    padding: 0.625rem;
    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;
    }
    &-title {
      font-weight: 700;
      font-size: 0.875rem;
      color: #fff;
    }
  }

  &__bottom {
    height: 60%;
    display: flex;
    flex-direction: column;
    background: #292c36;
  }
}
