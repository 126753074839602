.my-debt-account {
  width: 100%;
  &__header {
    font-weight: 700;
    font-size: 1.375rem;
    margin: 1.25rem 0 1.25rem 0.625rem;
  }
  &__filters {
    padding: 0 0.625rem;
  }
}
