.list-item {
  border-radius: 0.625rem;
  &_active {
    background: #da4167;
    box-shadow: 0 0.3125rem 0.9375rem rgba(218, 65, 103, 0.55);
  }

  &_active &__parent__title {
    color: #fff;
  }

  &__parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__img-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__td {
      display: flex;
      align-items: center;
    }

    &__title {
      font-weight: 700;
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.4);
      text-transform: capitalize;
    }

    &__img-arrow {
      width: 1.875rem;
      svg {
        width: 100%;
        height: 100%;
      }
      &_opened {
        transform: rotate(180deg);
      }
    }
  }

  &__parent_level {
    &_1 {
      padding: 0.625rem;
      .list-item__parent__title {
        color: #fff;
        font-size: 0.875rem;
      }
    }
  }
}
