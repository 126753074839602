.dropdown {
  position: relative;

  &_isMobile &__items {
    top: 2.5rem;
  }

  &__action {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-icon {
      display: flex;
      width: 0.375rem;
      height: 1.375rem;
      border-radius: 50%;
    }

    &:hover {
      border-radius: 50%;
      background: #020f1d;

      svg {
        circle {
          fill: #fff;
        }
      }
    }
  }
  &__items {
    position: absolute;
    padding: 0.625rem;
    top: 4.125rem;
    right: 40%;
    background: #292c36;
    border: 0.0625rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.625rem;
    width: 100%;
    min-width: 11.6875rem;
    z-index: 2;
    max-height: 16.5rem;

    &_isMobile {
      left: unset;
      right: 0;
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }

    &-icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.75rem;
    }

    &-title {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1rem;
      color: rgba(255, 255, 255, 0.4);
    }
  }
}
