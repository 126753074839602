.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.75rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 700;
  user-select: none;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  outline: none;
  cursor: pointer;
  background: #da4167;
  font-family: "Roboto";
  box-shadow: 0 0.3125rem 0.9375rem rgba(218, 65, 103, 0.55);
  border-radius: 0.625rem;

  &__search {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
  }

  &_colors {
    &_transparent {
      background-color: transparent;
      border: 0.0625rem solid #ffffff;
      box-shadow: none;
    }
    &_full-transparent {
      background-color: transparent;
      box-shadow: none;
      color: #da4167;
    }
    &_green {
      background-color: green;
      box-shadow: none;
    }
  }

  &_sizable {
    &_default {
      height: 100%;
    }

    &_low {
      height: 2.5rem;
    }

    &_high {
      height: 3.125rem;
    }

    &_sign {
      height: 2rem;
    }
  }

  &_disable {
    filter: grayscale(0.5);
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.2;
  }

  &_upperCased {
    text-transform: uppercase;
  }
}
