.sport-bet-report-table-row-pagination {
  &__tr {
    background: #292c36;
    border-radius: 0.625rem;
    height: 3.25rem;
    padding: 0.625rem 0;
    width: 100%;
    display: flex;

    &_isExpanded {
      border-radius: 0.625rem 0.625rem 0 0;
    }
  }
  &__td {
    width: 20%;
    max-width: 20%;
    height: 100%;
    min-width: 10%;
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0 1.25rem;
    color: #fff;
    text-transform: capitalize;
    position: relative;

    & span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:not(:first-child) {
      border-left: 1px solid rgba(255, 255, 255, 0.5);
    }
    &:first-child {
      justify-content: flex-start;
    }
  }

  &__status {
    &_win {
      color: #0d9252;
    }
    &_lost {
      color: #d31c32;
    }
    &_accepted {
      color: #ffc700;
    }
    &_notResulted {
      color: #6d6d6c;
    }
  }

  &__user-block {
    display: flex;
    align-items: center;
  }

  &__user-id {
    font-size: 0.875rem;
    font-weight: 400;
  }

  &__type-user-img {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }

  &__user-info {
    display: grid;
    grid-template-rows: 1fr 1fr;
    text-align: start;
  }

  &__user-type {
    color: #0094ff;
    font-size: 0.6875rem;
    font-weight: 400;
  }

  &__arrow-dropdown {
    position: absolute;
    right: 0.625rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  &__details {
    background-color: #292c36;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    width: 100%;
  }

  &__details-item {
    font-size: 0.875rem;
    color: #fff;
    display: flex;
    align-items: center;
  }

  &__details-head {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    background-color: #121417;
    border-radius: 0.3125rem;
    margin: 0 0.625rem;

    div {
      padding: 0.3125rem 0.625rem;
      color: #6d6d6d;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 700;
    }
  }

  &__details-item-row {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    width: 100%;
  }

  &__details-item-cell {
    padding: 0.625rem 1.25rem;
    align-items: center;
    display: flex;
    position: relative;

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      height: 50%;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      transform: translateY(-50%);
    }
  }

  &__expanded {
    svg {
      transform: rotate(180deg);
    }
  }

  &__details-item-cell-value {
    font-size: 0.6875rem;
    font-weight: 400;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_win {
      color: #0d9252; /* Цвет для статуса "Win" */
    }
    &_lost {
      color: #d31c32; /* Цвет для статуса "Lost" */
    }
    &_accepted {
      color: #ffc700; /* Цвет для статуса "Accepted" */
    }
    &_notResulted {
      color: #6d6d6c; /* Цвет для статуса "Not resulted" */
    }
  }
}
