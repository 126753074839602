.player-history {
  width: 100%;
  margin-right: 1.25rem;
  position: relative;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &-row {
      display: flex;
      gap: 0.625rem;

      &-data {
        flex: 1;

        &-view {
          height: 2.5rem;
        }

        &-title {
          font-family: Roboto;
          font-size: 0.75rem;
          font-weight: 500;
          margin-bottom: 2px;
        }
      }
    }

    &-title {
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 700;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-cross {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
