.notifies-view {
  display: flex;
  flex-direction: column;
  width: 20rem;
  box-sizing: border-box;
  padding: 1.25rem 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid rgba(255, 255, 255, 0.30);
  background-color: #020F1D;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 0.625rem;
  }

  &__title {
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: #fff;
  }

  &__cross {
    cursor: pointer;
    display: flex;

    &-svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0.625rem;
    border-radius: 0.625rem;
    background-color: #292C36;

    &:not(:last-child) {
      margin: 0 0 0.625rem;
    }
  }

  &__item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 1.5rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500;

    &:last-child {
      min-height: auto;
    }
  }

  &__item-right, &__item-left {
    display: flex;
    align-items: center;
  }

  &__type {
    color: #FFC700;
  }

  &__date {
    color: rgba(255, 255, 255, 0.40);
    margin-right: 0.625rem;
  }

  &__name {
    color: #fff;
    font-weight: 700;
  }

  &__amount {
    color: #DA4167;
    font-weight: 700;
  }

  &__new {
    padding: 0.313rem;
    margin-right: 0.313rem;
    border-radius:  0.313rem;
    background: #DA4167;
    box-shadow: 0px 0.313rem 0.938rem 0px rgba(218, 65, 103, 0.55);
  }
}
