.users-filter-block {
  display: flex;
  height: 2.5rem;
  display: flex;
  width: 100%;
  position: relative;

  &__input {
    width: 100%;
    min-width: 10rem;
    position: relative;
  }

  &__notify {
    position: absolute;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 0.75rem;
    top: calc(100% + 0.25rem);
  }

  &__icon {
    & svg {
      width: 1.5rem;
    }
    &-wrapper {
      border: none;
      margin-left: 0.625rem;
      background: #da4167;
      box-shadow: 0 0.3125rem 0.9375rem rgba(218, 65, 103, 0.55);
      border-radius: 0.625rem;
      padding: 0.625rem;
      cursor: pointer;
    }
  }
}
