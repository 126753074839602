.locale {
  display: flex;
  width: 100%;

  &__item {
    display: flex;
    align-items: center;
  }

  &__image {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.625rem;
    cursor: pointer;
  }
}
