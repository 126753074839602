.information-tree {
  width: 100%;
  height: 100%;

  &_isMobile {
    margin-left: 0;
    border-radius: 0;
    overflow-y: auto;
  }

  &__title {
    padding: 0.3125rem 0 0.625rem 0.625rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
  }

  &__back-arrow {
    width: 1.875rem;
    min-width: 1.875rem;
    display: flex;
    transform: rotate(180deg);
  }

  &_active &__current-agent {
    border: 1px solid #da4167;
  }

  &__current-agent {
    display: flex;
    border-radius: 0.625rem;
    background: #292c36;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.125rem;
    padding: 0.3125rem;
    height: 2.5rem;
    box-sizing: border-box;
    border: 1px solid #292c36;

    &__wrapper {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
    }

    &:hover {
      border: 1px solid #da4167;
    }

    &__img-user {
      width: 1.25rem;
      min-width: 1.25rem;
    }

    &__title {
      font-weight: 700;
      font-size: 0.875rem;
      color: #fff;
      margin-left: 0.625rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__img-arrow {
      width: 1.875rem;
      min-width: 1.875rem;
      transform: rotate(180deg);

      &_opened {
        transform: rotate(0);
      }
    }
  }

  &__wrapper {
    overflow-x: auto;
    padding: 0 0 0.625rem;
  }
}
