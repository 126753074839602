.sport-bet-table-head-pagination {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  box-sizing: border-box;

  &__table-head {
    // width: 20%;
    font-weight: 400;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #fff;
    text-align: left;
    padding: 0.625rem;

    &:first-child {
      padding-left: 0.625rem;
      text-align: left;
    }
  }
}
